var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.lib.aggregation.js");require("./metabase.lib.binning.js");require("./metabase.lib.card.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.equality.js");require("./metabase.lib.expression.js");require("./metabase.lib.join.js");require("./metabase.lib.join.util.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.normalize.js");require("./metabase.lib.options.js");require("./metabase.lib.ref.js");require("./metabase.lib.remove_replace.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.metadata.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.types.isa.js");require("./metabase.lib.util.js");require("./shadow.js.shim.module$ttag.js");require("./metabase.shared.util.time.js");require("./metabase.util.js");require("./metabase.util.humanization.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");require("./metabase.util.malli.registry.js");
'use strict';var j7,k7,l7,ota,pta,qta,o7,p7,r7,s7,rta,sta,tta,u7,v7,w7,uta,vta;j7=function(a){var b=new $CLJS.h(null,3,[$CLJS.vD,$CLJS.p.h($CLJS.FD()),$CLJS.tA,$CLJS.tA.h(a),$CLJS.oi,$CLJS.zz($CLJS.oi,$CLJS.tA)(a)],null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.qA,b,$CLJS.zz($CLJS.mD,$CLJS.U)(a)],null)};k7=function(a){return $CLJS.R.j(a,$CLJS.Vs,$CLJS.B4)};l7=function(a,b){return $CLJS.E.g($CLJS.hD.h(a),$CLJS.Bl(b,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zD,$CLJS.gD,$CLJS.yD],null)))};
ota=function(a){return $CLJS.O0(a,new $CLJS.h(null,1,[$CLJS.zD,$CLJS.mh],null))};
pta=function(a,b){var c=$CLJS.Me(function(e){return $CLJS.qG(function(f){return $CLJS.E.g($CLJS.J.g(f,e),a)},b)},new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.JY,$CLJS.U],null));if($CLJS.n(c))return c;c=$CLJS.Mz($CLJS.Ex);if($CLJS.n($CLJS.Lz("metabase.lib.field",c))){var d=$CLJS.dD("Invalid :field clause: column {0} does not exist. Found: {1}",$CLJS.H([$CLJS.xh.l($CLJS.H([a])),$CLJS.xh.l($CLJS.H([$CLJS.hk.g($CLJS.JY,b)]))]));d instanceof Error?$CLJS.Kz("metabase.lib.field",c,$CLJS.xv(),d):$CLJS.Kz("metabase.lib.field",
c,$CLJS.xv.l($CLJS.H([d])),null)}return null};
qta=function(a,b,c){if($CLJS.n(m7))return null;var d=m7;m7=!0;try{var e=$CLJS.H1(a,b),f=$CLJS.n(e)?$CLJS.DV(a,e):$CLJS.DV(a,b),k=function(){var m=$CLJS.n7.h(f);if($CLJS.n(m))return m;m=$CLJS.Bz(f,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mU,$CLJS.AV],null));if($CLJS.n(m))return m;m=$CLJS.n(function(){var u=$CLJS.MU.h(f);if($CLJS.n(u))return u;u=$CLJS.dO.h(f);if($CLJS.n(u))return u;u=$CLJS.YO.h(f);return $CLJS.n(u)?u:$CLJS.PD.h(f)}())?$CLJS.W_.j(a,b,f):null;if($CLJS.n(m))return m;m=$CLJS.Mz($CLJS.Ex);if($CLJS.n($CLJS.Lz("metabase.lib.field",
m))){var t=$CLJS.dD("Cannot resolve column {0}: stage has no metadata",$CLJS.H([$CLJS.xh.l($CLJS.H([c]))]));return t instanceof Error?$CLJS.Kz("metabase.lib.field",m,$CLJS.xv(),t):$CLJS.Kz("metabase.lib.field",m,$CLJS.xv.l($CLJS.H([t])),null)}return null}(),l=function(){var m=$CLJS.y(k);return m?pta(c,k):m}();return $CLJS.n(l)?$CLJS.n(e)?$CLJS.R.j($CLJS.R.j($CLJS.h3($CLJS.Yj.l(l,$CLJS.Ii,$CLJS.H([$CLJS.YJ,$CLJS.MV,$CLJS.HV])),null),$CLJS.U,function(){var m=$CLJS.JY.h(l);return $CLJS.n(m)?m:$CLJS.U.h(l)}()),
$CLJS.LJ,$CLJS.uY):l:null}finally{m7=d}};
o7=function(a,b,c){$CLJS.I(c,0,null);var d=$CLJS.I(c,1,null),e=$CLJS.O(d);d=$CLJS.J.g(e,$CLJS.sO);var f=$CLJS.I(c,2,null);c=$CLJS.xk.l($CLJS.H([function(){var k=$CLJS.tA.h(e);return $CLJS.n(k)?new $CLJS.h(null,1,[$CLJS.tA,k],null):null}(),function(){var k=$CLJS.zz($CLJS.oi,$CLJS.tA)(e);return $CLJS.n(k)?new $CLJS.h(null,1,[$CLJS.oi,k],null):null}(),function(){var k=$CLJS.sQ.h(e);return $CLJS.n(k)?new $CLJS.h(null,1,[$CLJS.MV,k],null):null}(),function(){var k=$CLJS.nF.h(e);return $CLJS.n(k)?new $CLJS.h(null,
1,[$CLJS.HV,k],null):null}(),$CLJS.Ad(f)?function(){var k=$CLJS.N1(a,b,f);return $CLJS.n(k)?k:new $CLJS.h(null,2,[$CLJS.Vs,$CLJS.JJ,$CLJS.U,$CLJS.p.h(f)],null)}():$CLJS.n(d)?new $CLJS.h(null,2,[$CLJS.Vs,$CLJS.JJ,$CLJS.U,$CLJS.p.h(f)],null):function(){var k=qta(a,b,f);return $CLJS.n(k)?k:new $CLJS.h(null,2,[$CLJS.Vs,$CLJS.JJ,$CLJS.U,$CLJS.p.h(f)],null)}()]));return $CLJS.n(d)?$CLJS.h3(c,d):c};
p7=function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.HV);return $CLJS.n($CLJS.n(b)?$CLJS.Cd($CLJS.nG,b):b)?$CLJS.$i:$CLJS.zz($CLJS.oi,$CLJS.tA)(a)};
$CLJS.q7=function(a,b,c,d){$CLJS.I(d,0,null);var e=$CLJS.I(d,1,null),f=$CLJS.O(e),k=$CLJS.J.g(f,$CLJS.vD),l=$CLJS.J.g(f,$CLJS.tA),m=$CLJS.J.g(f,$CLJS.sQ),t=$CLJS.J.g(f,$CLJS.oi);e=$CLJS.J.g(f,$CLJS.sO);var u=$CLJS.J.g(f,$CLJS.DM),v=$CLJS.J.g(f,$CLJS.nF),x=$CLJS.xk.l;k=new $CLJS.h(null,2,[$CLJS.Vs,$CLJS.JJ,$CLJS.B0,k],null);f=$CLJS.iD.h(f);a=$CLJS.n(f)?f:$CLJS.j_.j(a,b,d);c=x.call($CLJS.xk,$CLJS.H([k,c,new $CLJS.h(null,1,[$CLJS.iD,a],null)]));c=$CLJS.n(t)?$CLJS.R.j(c,$CLJS.oi,t):c;l=$CLJS.n(l)?$CLJS.R.j(c,
$CLJS.tA,l):c;v=$CLJS.n(v)?$CLJS.R.j(l,$CLJS.HV,v):l;m=$CLJS.n(m)?$CLJS.R.j(v,$CLJS.MV,m):v;u=$CLJS.n(u)?$CLJS.R.j(m,$CLJS.XJ,u):m;return $CLJS.n(e)?$CLJS.h3(u,e):u};r7=function(a,b,c){return $CLJS.n($CLJS.Me(function(d){return $CLJS.E.g($CLJS.di.h(d),c)},a))?$CLJS.hk.g(function(d){var e=$CLJS.Cd(d,b)?$CLJS.Yj.l(d,d,$CLJS.H([b])):d;return $CLJS.E.g($CLJS.di.h(d),c)?$CLJS.R.j(e,b,!0):e},a):a};
s7=function(a){var b=$CLJS.n($CLJS.o1.h(a))?null:function(){var e=$CLJS.LJ.h(a),f=new $CLJS.Rg(null,new $CLJS.h(null,3,[$CLJS.NY,null,$CLJS.UY,null,$CLJS.uY,null],null),null);return f.h?f.h(e):f.call(null,e)}(),c=$CLJS.xk.l($CLJS.H([new $CLJS.h(null,3,[$CLJS.vD,$CLJS.p.h($CLJS.FD()),$CLJS.tA,$CLJS.tA.h(a),$CLJS.oi,p7(a)],null),function(){var e=function(){var f=$CLJS.Ra(b);return f?(f=$CLJS.Ra($CLJS.XJ.h(a)))?(f=$CLJS.ek.g($CLJS.FY,$CLJS.LJ.h(a)))?$CLJS.YY.h(a):f:f:f}();return $CLJS.n(e)?new $CLJS.h(null,
1,[$CLJS.sO,e],null):null}(),function(){var e=$CLJS.h1(a);return $CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.sO,e],null):null}(),function(){var e=$CLJS.HV.h(a);return $CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.nF,e],null):null}(),function(){var e=$CLJS.LV.h(a);return $CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.LV,e],null):null}(),function(){var e=$CLJS.MV.h(a);return $CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.sQ,e],null):null}(),function(){var e=$CLJS.XJ.h(a);return $CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.DM,e],null):null}()])),
d=($CLJS.n(b)?$CLJS.zz($CLJS.JY,$CLJS.U):$CLJS.zz($CLJS.Ii,$CLJS.U))(a);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hF,c,d],null)};rta=function(a,b){return $CLJS.ff(function(c){return $CLJS.E.g($CLJS.LJ.h(c),$CLJS.DY)},$CLJS.W_.v(a,b,$CLJS.DV(a,b),new $CLJS.h(null,3,[$CLJS.D_,!1,$CLJS.J_,!0,$CLJS.z_,!1],null)))};sta=new $CLJS.M("lib","simple-name","lib/simple-name",-734346338);tta=new $CLJS.M("metabase.lib.field","field-values-search-info","metabase.lib.field/field-values-search-info",-468454179);
$CLJS.t7=new $CLJS.M(null,"search-field-id","search-field-id",-699326724);u7=new $CLJS.M("metabase.lib.field","field-values-search-info.has-field-values","metabase.lib.field/field-values-search-info.has-field-values",1753198226);v7=new $CLJS.M(null,"parent-id","parent-id",-1400729131);$CLJS.n7=new $CLJS.M("metabase.lib.stage","cached-metadata","metabase.lib.stage/cached-metadata",-1406479151);w7=new $CLJS.M("metabase.lib.field","simple-display-name","metabase.lib.field/simple-display-name",494797345);
uta=new $CLJS.M(null,"earliest","earliest",-1928154382);vta=new $CLJS.M(null,"latest","latest",24323665);$CLJS.P0.m(null,$CLJS.hF,function(a){var b=$CLJS.I(a,0,null),c=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mh.h(b),$CLJS.O0(c,new $CLJS.h(null,2,[$CLJS.nF,$CLJS.mh,$CLJS.sQ,ota],null)),a],null)});
var m7=!1,x7=function x7(a,b){var d=$CLJS.Y0(a,v7.h(b));a=$CLJS.n(v7.h(d))?x7.g?x7.g(a,d):x7.call(null,a,d):d;a=$CLJS.O(a);var e=$CLJS.J.g(a,$CLJS.U),f=$CLJS.J.g(a,$CLJS.iD);return $CLJS.ik.j($CLJS.R.j($CLJS.ik.j($CLJS.R.j(b,sta,$CLJS.U.h(b)),$CLJS.U,function(k){return[$CLJS.p.h(e),".",$CLJS.p.h(k)].join("")}),w7,$CLJS.iD.h(b)),$CLJS.iD,function(k){return[$CLJS.p.h(f),": ",$CLJS.p.h(k)].join("")})};$CLJS.O_.m(null,$CLJS.JJ,function(a,b,c){return p7(c)});
$CLJS.O_.m(null,$CLJS.hF,function(a,b,c){$CLJS.I(c,0,null);var d=$CLJS.I(c,1,null);d=$CLJS.O(d);d=$CLJS.J.g(d,$CLJS.nF);$CLJS.I(c,2,null);c=o7(a,b,c);c=$CLJS.n(d)?$CLJS.R.j(c,$CLJS.HV,d):c;return $CLJS.P_.j(a,b,c)});$CLJS.R_.m(null,$CLJS.JJ,function(a,b,c){a=$CLJS.O(c);b=$CLJS.J.g(a,$CLJS.U);return $CLJS.R.j(a,$CLJS.U,b)});$CLJS.R_.m(null,$CLJS.hF,function(a,b,c){var d=o7(a,b,c);b=$CLJS.q7(a,b,d,c);return $CLJS.n(v7.h(b))?x7(a,b):b});
$CLJS.K_.m(null,$CLJS.JJ,function(a,b,c,d){function e(T){var da=$CLJS.g5(l,k);return $CLJS.ha.j?$CLJS.ha.j("%s: %s",T,da):$CLJS.ha.call(null,"%s: %s",T,da)}function f(T){var da=$CLJS.cA($CLJS.Gz($CLJS.Xg(u),"-"," "));return $CLJS.ha.j?$CLJS.ha.j("%s: %s",T,da):$CLJS.ha.call(null,"%s: %s",T,da)}var k=$CLJS.O(c),l=$CLJS.J.g(k,$CLJS.MV),m=$CLJS.J.g(k,$CLJS.XJ),t=$CLJS.J.g(k,$CLJS.YJ),u=$CLJS.J.g(k,$CLJS.di),v=$CLJS.J.g(k,$CLJS.YY),x=$CLJS.J.g(k,w7),A=$CLJS.J.g(k,$CLJS.U),C=$CLJS.J.g(k,$CLJS.G5),G=$CLJS.J.g(k,
$CLJS.iD),K=$CLJS.J.g(k,v7),S=$CLJS.f1.g($CLJS.e1,A);c=function(){if($CLJS.n(x))return x;if($CLJS.n($CLJS.n(K)?null==G||$CLJS.E.g(G,S):K)){a:{var T=$CLJS.O(k);var da=$CLJS.J.g(T,$CLJS.iD);T=$CLJS.J.g(T,v7);for(da=new $CLJS.Vd(null,da,null,1,null);;)if($CLJS.n(T)){T=$CLJS.Y0(a,T);var oa=$CLJS.O(T);T=$CLJS.J.g(oa,$CLJS.iD);oa=$CLJS.J.g(oa,v7);da=$CLJS.Yd.g(da,T);T=oa}else break a}da=$CLJS.Le($CLJS.Sa,da)?$CLJS.hs(": ",da):null}else da=null;return $CLJS.n(da)?da:$CLJS.n(G)?G:"string"===typeof A?S:$CLJS.p.h(A)}();
var X=$CLJS.E.g(d,$CLJS.k_)&&-1==c.indexOf(" → ")?function(){if($CLJS.n(m)){var T=$CLJS.Y0(a,m);$CLJS.n(T)?T=$CLJS.d3($CLJS.iD.h($CLJS.m_.j(a,b,T))):(T=$CLJS.g1(a,t),T=$CLJS.j_.v(a,b,T,d))}else T=null;return $CLJS.n(T)?T:$CLJS.n(v)?v:$CLJS.h1(k)}():null;c=$CLJS.n(X)?[$CLJS.p.h(X)," → ",$CLJS.p.h(c)].join(""):c;return $CLJS.n(function(){var T=$CLJS.ek.g(d,$CLJS.k_);return T?C:T}())?c:$CLJS.n($CLJS.n(u)?$CLJS.ek.g(c,f(S)):u)?f(c):$CLJS.n($CLJS.n(l)?$CLJS.ek.g(c,e(S)):l)?e(c):c});
$CLJS.K_.m(null,$CLJS.hF,function(a,b,c,d){$CLJS.I(c,0,null);var e=$CLJS.I(c,1,null),f=$CLJS.O(e);e=$CLJS.J.g(f,$CLJS.sQ);var k=$CLJS.J.g(f,$CLJS.sO),l=$CLJS.J.g(f,$CLJS.nF);f=$CLJS.J.g(f,$CLJS.DM);$CLJS.I(c,2,null);c=o7(a,b,c);c=$CLJS.n(k)?$CLJS.R.j(c,$CLJS.YY,k):c;l=$CLJS.n(l)?$CLJS.R.j(c,$CLJS.di,l):c;e=$CLJS.n(e)?$CLJS.R.j(l,$CLJS.MV,e):l;e=$CLJS.n(f)?$CLJS.R.j(e,$CLJS.XJ,f):e;return $CLJS.n(e)?$CLJS.j_.v(a,b,e,d):$CLJS.GD("[Unknown Field]")});
$CLJS.L_.m(null,$CLJS.JJ,function(a,b,c){a=$CLJS.O(c);return $CLJS.J.g(a,$CLJS.U)});$CLJS.L_.m(null,$CLJS.hF,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=o7(a,b,c);return $CLJS.n(c)?$CLJS.M_.j(a,b,c):"unknown_field"});
$CLJS.S_.m(null,$CLJS.JJ,function(a,b,c){return $CLJS.xk.l($CLJS.H([function(){var d=$CLJS.jH($CLJS.S_,$CLJS.Oh);return d.j?d.j(a,b,c):d.call(null,a,b,c)}(),new $CLJS.h(null,2,[$CLJS.iD,$CLJS.j_.j(a,b,c),$CLJS.l_,$CLJS.j_.v(a,b,c,$CLJS.k_)],null),$CLJS.E.g($CLJS.LJ.h(c),$CLJS.NY)?function(){var d=$CLJS.XY.h(c);return $CLJS.n(d)?(d=$CLJS.d_(a,d),$CLJS.n(d)?new $CLJS.h(null,1,[$CLJS.Jj,new $CLJS.h(null,2,[$CLJS.U,$CLJS.U.h(d),$CLJS.iD,$CLJS.U.h(d)],null)],null):null):null}():null]))});
$CLJS.s0.m(null,$CLJS.hF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);$CLJS.I(a,2,null);return $CLJS.nF.h(b)});$CLJS.s0.m(null,$CLJS.JJ,function(a){return $CLJS.HV.h(a)});
$CLJS.r0.m(null,$CLJS.hF,function(a,b){$CLJS.I(a,0,null);var c=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);if($CLJS.n(b)){var d=$CLJS.Cd($CLJS.nG,b),e=$CLJS.pB($CLJS.LV,$CLJS.oi,$CLJS.tA)(c);c=$CLJS.R.l(c,$CLJS.nF,b,$CLJS.H([$CLJS.oi,d?$CLJS.$i:e,$CLJS.LV,e]));return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hF,c,a],null)}b=$CLJS.LV.h(c);c=$CLJS.n(b)?$CLJS.Yj.g($CLJS.R.j(c,$CLJS.oi,b),$CLJS.LV):c;c=$CLJS.Yj.g(c,$CLJS.nF);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hF,c,a],null)});
$CLJS.r0.m(null,$CLJS.JJ,function(a,b){return $CLJS.n(b)?$CLJS.R.l(a,$CLJS.HV,b,$CLJS.H([$CLJS.LV,$CLJS.pB($CLJS.LV,$CLJS.oi,$CLJS.tA)(a)])):$CLJS.Yj.l(a,$CLJS.HV,$CLJS.H([$CLJS.LV]))});$CLJS.u0.m(null,$CLJS.hF,function(a,b,c){return $CLJS.v0.j(a,b,o7(a,b,c))});
$CLJS.u0.m(null,$CLJS.JJ,function(a,b,c){if($CLJS.ek.g($CLJS.LJ.h(c),$CLJS.DY)){a=$CLJS.zz($CLJS.oi,$CLJS.tA)(c);b=null==c?null:$CLJS.RM.h(c);if(null==b)var d=null;else try{var e=$CLJS.Fk.h($CLJS.Ti.h(b));if($CLJS.n(e)){var f=$CLJS.O(e),k=$CLJS.J.g(f,uta),l=$CLJS.J.g(f,vta),m=$CLJS.kpa.l($CLJS.H([$CLJS.p0.h(k),$CLJS.p0.h(l)]));d=isNaN(m)?null:$CLJS.n($CLJS.Aq.g?$CLJS.Aq.g(1,m):$CLJS.Aq.call(null,1,m))?$CLJS.Ci:$CLJS.n($CLJS.Aq.g?$CLJS.Aq.g(31,m):$CLJS.Aq.call(null,31,m))?$CLJS.Kj:$CLJS.n($CLJS.Aq.g?
$CLJS.Aq.g(365,m):$CLJS.Aq.call(null,365,m))?$CLJS.Vi:$CLJS.aj}else d=null}catch(t){if(t instanceof Error)d=null;else throw t;}e=$CLJS.Dz(a,$CLJS.Fk)?$CLJS.vpa:$CLJS.Dz(a,$CLJS.Ck)?$CLJS.upa:$CLJS.Dz(a,$CLJS.Kk)?$CLJS.tpa:$CLJS.xf;d=$CLJS.n(d)?r7(e,$CLJS.Oh,d):e;return $CLJS.n($CLJS.HV.h(c))?r7(d,$CLJS.r_,$CLJS.HV.h(c)):d}return $CLJS.xf});
$CLJS.d5.m(null,$CLJS.hF,function(a){var b=null==a?null:$CLJS.CD(a);b=null==b?null:$CLJS.sQ.h(b);return null==b?null:$CLJS.R.l(b,$CLJS.Vs,$CLJS.i5,$CLJS.H([$CLJS.h5,function(c,d){return o7(c,d,a)}]))});$CLJS.d5.m(null,$CLJS.JJ,function(a){var b=null==a?null:$CLJS.MV.h(a);return null==b?null:$CLJS.R.l(b,$CLJS.Vs,$CLJS.i5,$CLJS.H([$CLJS.h5,$CLJS.Pe(a)]))});$CLJS.j5.m(null,$CLJS.hF,function(a,b){return $CLJS.DD(a,$CLJS.BD,$CLJS.H([$CLJS.sQ,b]))});
$CLJS.j5.m(null,$CLJS.JJ,function(a,b){return $CLJS.BD(a,$CLJS.MV,b)});$CLJS.k5.m(null,$CLJS.hF,function(a,b,c){return $CLJS.l5.j(a,b,o7(a,b,c))});
$CLJS.k5.m(null,$CLJS.JJ,function(a,b,c){b=$CLJS.O(c);c=$CLJS.J.g(b,$CLJS.oi);var d=$CLJS.J.g(b,$CLJS.RM),e=$CLJS.J.g(b,$CLJS.sj);if($CLJS.ek.g($CLJS.LJ.h(b),$CLJS.DY)){var f=function(){var m=null==a?null:$CLJS.X0(a);m=null==m?null:$CLJS.dz.h(m);return null==m?null:$CLJS.Cd(m,$CLJS.sQ)}(),k=$CLJS.Bz(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ti,$CLJS.oj],null)),l=$CLJS.e5(b);return function u(t){return new $CLJS.ie(null,function(){for(;;){var v=$CLJS.y(t);if(v){if($CLJS.vd(v)){var x=$CLJS.ic(v),A=$CLJS.D(x),
C=$CLJS.le(A);return function(){for(var K=0;;)if(K<A){var S=$CLJS.hd(x,K),X=C,T=S;S=l7(S,l)?$CLJS.R.j(T,$CLJS.r_,!0):T;X.add(S);K+=1}else return!0}()?$CLJS.oe($CLJS.qe(C),u($CLJS.jc(v))):$CLJS.oe($CLJS.qe(C),null)}var G=$CLJS.z(v);return $CLJS.ae(function(){var K=G;return l7(G,l)?$CLJS.R.j(K,$CLJS.r_,!0):K}(),u($CLJS.Hc(v)))}return null}},null,null)}($CLJS.Ra(function(){if($CLJS.n(f)){var t=$CLJS.si.h(k);return $CLJS.n(t)?$CLJS.Bj.h(k):t}return f}())?null:$CLJS.Dz(e,$CLJS.bj)?$CLJS.hk.g(k7,new $CLJS.P(null,
5,5,$CLJS.Q,[$CLJS.f5(),new $CLJS.h(null,2,[$CLJS.iD,$CLJS.GD("Bin every 0.1 degrees"),$CLJS.hD,new $CLJS.h(null,2,[$CLJS.zD,$CLJS.yD,$CLJS.yD,.1],null)],null),new $CLJS.h(null,2,[$CLJS.iD,$CLJS.GD("Bin every 1 degree"),$CLJS.hD,new $CLJS.h(null,2,[$CLJS.zD,$CLJS.yD,$CLJS.yD,1],null)],null),new $CLJS.h(null,2,[$CLJS.iD,$CLJS.GD("Bin every 10 degrees"),$CLJS.hD,new $CLJS.h(null,2,[$CLJS.zD,$CLJS.yD,$CLJS.yD,10],null)],null),new $CLJS.h(null,2,[$CLJS.iD,$CLJS.GD("Bin every 20 degrees"),$CLJS.hD,new $CLJS.h(null,
2,[$CLJS.zD,$CLJS.yD,$CLJS.yD,20],null)],null)],null)):$CLJS.Dz(c,$CLJS.oj)&&!$CLJS.Dz(e,$CLJS.$h)?$CLJS.hk.g(k7,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.f5(),new $CLJS.h(null,2,[$CLJS.iD,$CLJS.GD("10 bins"),$CLJS.hD,new $CLJS.h(null,2,[$CLJS.zD,$CLJS.gD,$CLJS.gD,10],null)],null),new $CLJS.h(null,2,[$CLJS.iD,$CLJS.GD("50 bins"),$CLJS.hD,new $CLJS.h(null,2,[$CLJS.zD,$CLJS.gD,$CLJS.gD,50],null)],null),new $CLJS.h(null,2,[$CLJS.iD,$CLJS.GD("100 bins"),$CLJS.hD,new $CLJS.h(null,2,[$CLJS.zD,$CLJS.gD,$CLJS.gD,
100],null)],null)],null)):null)}return $CLJS.xf});$CLJS.mV.m(null,$CLJS.hF,function(a){return a});
$CLJS.mV.m(null,$CLJS.JJ,function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.LJ);switch(b instanceof $CLJS.M?b.S:null){case "source/aggregations":return b=new $CLJS.h(null,3,[$CLJS.vD,$CLJS.p.h($CLJS.FD()),$CLJS.oi,$CLJS.zz($CLJS.oi,$CLJS.tA)(a),$CLJS.yK,$CLJS.U.h(a)],null),a=$CLJS.B0.h(a),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.XE,b,a],null);case "source/expressions":return j7(a);case "source/fields":case "source/breakouts":return $CLJS.n($CLJS.mD.h(a))?j7(a):s7(a);default:return s7(a)}});
$CLJS.wta=function(){function a(e,f,k){k=$CLJS.Be($CLJS.hk.g($CLJS.oV,k));var l=rta(e,f),m=$CLJS.Wf.j($CLJS.Sg,$CLJS.tl(function(t){return $CLJS.T1.v(e,f,t,l)}),$CLJS.n(k)?k:$CLJS.xf);m=$CLJS.gk.g(m,l);k=$CLJS.n(k)?$CLJS.Wf.j(k,$CLJS.cf.h($CLJS.oV),m):null;return $CLJS.OV.l(e,f,$CLJS.BD,$CLJS.H([$CLJS.PD,k]))}function b(e,f){return d.j?d.j(e,-1,f):d.call(null,e,-1,f)}function c(e){return function(f,k){return d.j?d.j(f,k,e):d.call(null,f,k,e)}}var d=null;d=function(e,f,k){switch(arguments.length){case 1:return c.call(this,
e);case 2:return b.call(this,e,f);case 3:return a.call(this,e,f,k)}throw Error("Invalid arity: "+arguments.length);};d.h=c;d.g=b;d.j=a;return d}();$CLJS.y7=function(){function a(d,e){return $CLJS.PD.h($CLJS.DV(d,e))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.xta=function(){function a(d,e){var f=$CLJS.W_.v(d,e,$CLJS.DV(d,e),new $CLJS.h(null,3,[$CLJS.D_,!1,$CLJS.J_,!1,$CLJS.z_,!1],null)),k=$CLJS.y7.g(d,e);return $CLJS.od(k)?$CLJS.hk.g(function(l){return $CLJS.R.j(l,$CLJS.LY,!0)},f):$CLJS.f3(d,e,f,k)}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.Y(u7,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ar,$CLJS.ej,$CLJS.xY,$CLJS.cz],null));
$CLJS.Y(tta,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Aj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.TJ,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.fK],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.t7,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.fK],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.TY,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,u7],null)],null)],null));