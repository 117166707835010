var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.types.js");require("./metabase.util.malli.registry.js");
'use strict';var Nga,kD,oD,AD;
$CLJS.bD=function(a,b){return function(){function c(l,m,t){return $CLJS.zd(function(){var u=a.h?a.h(l):a.call(null,l);return $CLJS.n(u)?(u=a.h?a.h(m):a.call(null,m),$CLJS.n(u)?(u=a.h?a.h(t):a.call(null,t),$CLJS.n(u)?(u=b.h?b.h(l):b.call(null,l),$CLJS.n(u)?(u=b.h?b.h(m):b.call(null,m),$CLJS.n(u)?b.h?b.h(t):b.call(null,t):u):u):u):u):u}())}function d(l,m){return $CLJS.zd(function(){var t=a.h?a.h(l):a.call(null,l);return $CLJS.n(t)?(t=a.h?a.h(m):a.call(null,m),$CLJS.n(t)?(t=b.h?b.h(l):b.call(null,l),
$CLJS.n(t)?b.h?b.h(m):b.call(null,m):t):t):t}())}function e(l){var m=a.h?a.h(l):a.call(null,l);l=$CLJS.n(m)?b.h?b.h(l):b.call(null,l):m;return $CLJS.zd(l)}var f=null,k=function(){function l(t,u,v,x){var A=null;if(3<arguments.length){A=0;for(var C=Array(arguments.length-3);A<C.length;)C[A]=arguments[A+3],++A;A=new $CLJS.w(C,0,null)}return m.call(this,t,u,v,A)}function m(t,u,v,x){return $CLJS.zd(function(){var A=f.j(t,u,v);return $CLJS.n(A)?$CLJS.Le(function(C){var G=a.h?a.h(C):a.call(null,C);return $CLJS.n(G)?
b.h?b.h(C):b.call(null,C):G},x):A}())}l.A=3;l.B=function(t){var u=$CLJS.z(t);t=$CLJS.B(t);var v=$CLJS.z(t);t=$CLJS.B(t);var x=$CLJS.z(t);t=$CLJS.Hc(t);return m(u,v,x,t)};l.l=m;return l}();f=function(l,m,t,u){switch(arguments.length){case 0:return!0;case 1:return e.call(this,l);case 2:return d.call(this,l,m);case 3:return c.call(this,l,m,t);default:var v=null;if(3<arguments.length){v=0;for(var x=Array(arguments.length-3);v<x.length;)x[v]=arguments[v+3],++v;v=new $CLJS.w(x,0,null)}return k.l(l,m,t,
v)}throw Error("Invalid arity: "+arguments.length);};f.A=3;f.B=k.B;f.o=function(){return!0};f.h=e;f.g=d;f.j=c;f.l=k.l;return f}()};$CLJS.cD=function(a){return $CLJS.fk.g($CLJS.cf.h(a),$CLJS.qB)};$CLJS.dD=function(a,b){a=$CLJS.Ns($CLJS.Gz(a,/''/,"'"),/\{\d+\}/);return $CLJS.Ne.j($CLJS.Gs.t,$CLJS.zk(a),$CLJS.zk(b))};$CLJS.eD=new $CLJS.M("lib","metadata","lib/metadata",1798917220);$CLJS.fD=new $CLJS.M("metabase.lib.schema.common","positive-int","metabase.lib.schema.common/positive-int",-733946956);
$CLJS.gD=new $CLJS.M(null,"num-bins","num-bins",847672055);$CLJS.hD=new $CLJS.M(null,"mbql","mbql",69346710);$CLJS.iD=new $CLJS.M(null,"display-name","display-name",694513143);Nga=new $CLJS.M("metabase.lib.schema.common","external-op","metabase.lib.schema.common/external-op",1918684841);$CLJS.jD=new $CLJS.M("metabase.lib.schema.common","semantic-or-relation-type","metabase.lib.schema.common/semantic-or-relation-type",-158604052);
kD=new $CLJS.M("metabase.lib.schema.common","relation-type","metabase.lib.schema.common/relation-type",1412287664);$CLJS.lD=new $CLJS.M("lib","external-op","lib/external-op",-1470923877);$CLJS.mD=new $CLJS.M("lib","expression-name","lib/expression-name",-1799326590);$CLJS.nD=new $CLJS.M("metabase.lib.schema.common","non-blank-string","metabase.lib.schema.common/non-blank-string",-2032366619);oD=new $CLJS.M("metabase.lib.schema.common","semantic-type","metabase.lib.schema.common/semantic-type",-1623124128);
$CLJS.pD=new $CLJS.M(null,"database-type","database-type",-426840562);$CLJS.qD=new $CLJS.M(null,"operator","operator",-1860875338);$CLJS.rD=new $CLJS.M("metabase.lib.schema.common","int-greater-than-or-equal-to-zero","metabase.lib.schema.common/int-greater-than-or-equal-to-zero",-1646435681);$CLJS.sD=new $CLJS.M("metabase.lib.schema.binning","bin-width","metabase.lib.schema.binning/bin-width",538632740);
$CLJS.tD=new $CLJS.M("metabase.lib.schema.common","options","metabase.lib.schema.common/options",-1078359294);$CLJS.uD=new $CLJS.M("metabase.lib.schema.common","positive-number","metabase.lib.schema.common/positive-number",2061626826);$CLJS.vD=new $CLJS.M("lib","uuid","lib/uuid",-2145250720);$CLJS.wD=new $CLJS.M(null,"x","x",2099068185);$CLJS.xD=new $CLJS.M("metabase.lib.schema.common","base-type","metabase.lib.schema.common/base-type",999524206);
$CLJS.yD=new $CLJS.M(null,"bin-width","bin-width",1377922579);$CLJS.zD=new $CLJS.M(null,"strategy","strategy",-1471631918);AD=new $CLJS.M("metabase.lib.schema.common","uuid","metabase.lib.schema.common/uuid",1172938098);$CLJS.Y($CLJS.nD,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.vj,new $CLJS.h(null,1,[$CLJS.si,1],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ai,new $CLJS.h(null,1,[$CLJS.ts,"non-blank string"],null),$CLJS.Oe($CLJS.Hz)],null)],null));$CLJS.Y($CLJS.rD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pr,new $CLJS.h(null,1,[$CLJS.si,0],null)],null));$CLJS.Y($CLJS.fD,$CLJS.ll);
$CLJS.Y($CLJS.uD,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ai,new $CLJS.h(null,1,[$CLJS.ts,"positive number"],null),$CLJS.bD($CLJS.Yk,$CLJS.ql)],null));$CLJS.Y(AD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.vj,new $CLJS.h(null,2,[$CLJS.si,36,$CLJS.Bj,36],null)],null));
$CLJS.Y(oD,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ai,new $CLJS.h(null,2,[$CLJS.ts,"valid semantic type",$CLJS.Es,function(a){a=$CLJS.O(a);a=$CLJS.J.g(a,$CLJS.fj);return["Not a valid semantic type: ",$CLJS.xh.l($CLJS.H([a]))].join("")}],null),function(a){return $CLJS.Dz(a,$CLJS.Yi)}],null));
$CLJS.Y(kD,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ai,new $CLJS.h(null,2,[$CLJS.ts,"valid relation type",$CLJS.Es,function(a){a=$CLJS.O(a);a=$CLJS.J.g(a,$CLJS.fj);return["Not a valid relation type: ",$CLJS.xh.l($CLJS.H([a]))].join("")}],null),function(a){return $CLJS.Dz(a,$CLJS.$h)}],null));$CLJS.Y($CLJS.jD,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,oD],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,kD],null)],null));
$CLJS.Y($CLJS.xD,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ai,new $CLJS.h(null,2,[$CLJS.ts,"valid base type",$CLJS.Es,function(a){a=$CLJS.O(a);a=$CLJS.J.g(a,$CLJS.fj);return["Not a valid base type: ",$CLJS.xh.l($CLJS.H([a]))].join("")}],null),function(a){return $CLJS.Dz(a,$CLJS.dj)}],null));
$CLJS.Y($CLJS.tD,new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.Aj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.vD,AD],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.tA,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,$CLJS.xD],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.oi,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,$CLJS.xD],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.sj,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,
$CLJS.jD],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.pD,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,$CLJS.nD],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.U,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,$CLJS.nD],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.iD,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,$CLJS.nD],null)],null)],null));
$CLJS.Y(Nga,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Aj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gq,$CLJS.lD],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qD,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dr,$CLJS.vj,$CLJS.Gi],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Kr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cj,$CLJS.ur],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Oi,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),$CLJS.tD],null)],null));