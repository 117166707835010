var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.util.malli.registry.js");
'use strict';var MI;$CLJS.KI=function(a){return $CLJS.n($CLJS.pB($CLJS.rd,$CLJS.qd,$CLJS.Xk)(a))?a:new $CLJS.P(null,1,5,$CLJS.Q,[a],null)};
$CLJS.LI=function(a,b){return $CLJS.Me($CLJS.zd,function(){return function e(d){return new $CLJS.ie(null,function(){for(var f=d;;){var k=$CLJS.y(f);if(k){var l=k,m=$CLJS.z(l);if(k=$CLJS.y(function(t,u,v,x){return function G(C){return new $CLJS.ie(null,function(K,S,X,T){return function(){for(var da=C;;){var oa=$CLJS.y(da);if(oa){var Ma=oa,sb=$CLJS.z(Ma);if(oa=$CLJS.y(function(Qa,Xa,Ja,Ua,Ta,mb,dc,Kd){return function Rh(gd){return new $CLJS.ie(null,function(qz,Hl,Un,yr,Q_,Nv){return function(){for(;;){var Ov=
$CLJS.y(gd);if(Ov){if($CLJS.vd(Ov)){var Pv=$CLJS.ic(Ov),rz=$CLJS.D(Pv),Ek=$CLJS.le(rz);a:for(var zr=0;;)if(zr<rz){var gt=$CLJS.hd(Pv,zr);gt=$CLJS.E.g(Nv,$CLJS.WD)||$CLJS.E.g(Un,$CLJS.WD)||$CLJS.xE(Nv,gt)&&$CLJS.xE(Un,gt);Ek.add(gt);zr+=1}else{Pv=!0;break a}return Pv?$CLJS.oe($CLJS.qe(Ek),Rh($CLJS.jc(Ov))):$CLJS.oe($CLJS.qe(Ek),null)}Ek=$CLJS.z(Ov);return $CLJS.ae($CLJS.E.g(Nv,$CLJS.WD)||$CLJS.E.g(Un,$CLJS.WD)||$CLJS.xE(Nv,Ek)&&$CLJS.xE(Un,Ek),Rh($CLJS.Hc(Ov)))}return null}}}(Qa,Xa,Ja,Ua,Ta,mb,dc,
Kd),null,null)}}(da,K,sb,Ma,oa,S,X,T)($CLJS.hE)))return $CLJS.bf.g(oa,G($CLJS.Hc(da)));da=$CLJS.Hc(da)}else return null}}}(t,u,v,x),null,null)}}(f,m,l,k)($CLJS.KI($CLJS.JD(b)))))return $CLJS.bf.g(k,e($CLJS.Hc(f)));f=$CLJS.Hc(f)}else return null}},null,null)}($CLJS.KI($CLJS.JD(a)))}())};
MI=function(a){return function(){function b(d,e){var f=null;if(1<arguments.length){f=0;for(var k=Array(arguments.length-1);f<k.length;)k[f]=arguments[f+1],++f;f=new $CLJS.w(k,0,null)}return c.call(this,d,f)}function c(d,e){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kr,$CLJS.Ne.j($CLJS.tE,d,e),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ai,new $CLJS.h(null,1,[$CLJS.ts,"arguments should be comparable"],null),function(f){f=$CLJS.y(f);$CLJS.z(f);f=$CLJS.B(f);$CLJS.z(f);f=$CLJS.B(f);var k=$CLJS.yf(f);f=$CLJS.LD;
return $CLJS.n(f)?f:$CLJS.Le($CLJS.hl,$CLJS.cf.g(function(l){var m=$CLJS.I(l,0,null);l=$CLJS.I(l,1,null);return $CLJS.LI($CLJS.J.g(k,m),$CLJS.J.g(k,l))},a))}],null)],null)}b.A=1;b.B=function(d){var e=$CLJS.z(d);d=$CLJS.Hc(d);return c(e,d)};b.l=c;return b}()};$CLJS.NI=new $CLJS.M("metabase.lib.schema.filter","operator","metabase.lib.schema.filter/operator",-805746630);$CLJS.OI=new $CLJS.M("operator","filter","operator/filter",-1518842858);
$CLJS.PI=new $CLJS.M(null,"display-name-variant","display-name-variant",-1831788853);for(var QI=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kr,$CLJS.dr],null)),RI=null,SI=0,TI=0;;)if(TI<SI){var Kia=RI.X(null,TI);$CLJS.wE(Kia,$CLJS.H([$CLJS.Zr,$CLJS.mj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Kr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qq,new $CLJS.h(null,1,[$CLJS.si,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.UD],null)],null)],null)],null)]));TI+=1}else{var UI=$CLJS.y(QI);if(UI){var VI=UI;if($CLJS.vd(VI)){var WI=$CLJS.ic(VI),Lia=$CLJS.jc(VI),
Mia=WI,Nia=$CLJS.D(WI);QI=Lia;RI=Mia;SI=Nia}else{var Oia=$CLJS.z(VI);$CLJS.wE(Oia,$CLJS.H([$CLJS.Zr,$CLJS.mj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Kr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qq,new $CLJS.h(null,1,[$CLJS.si,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.UD],null)],null)],null)],null)]));QI=$CLJS.B(VI);RI=null;SI=0}TI=0}else break}$CLJS.uE($CLJS.fr,$CLJS.H([$CLJS.Zr,$CLJS.mj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.UD],null)]));
for(var XI=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gq,$CLJS.xF],null)),YI=null,ZI=0,$I=0;;)if($I<ZI){var Pia=YI.X(null,$I);$CLJS.wE(Pia,$CLJS.H([$CLJS.Zr,$CLJS.mj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Kr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qq,new $CLJS.h(null,1,[$CLJS.si,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.ZD],null)],null)],null)],null)]));$I+=1}else{var aJ=$CLJS.y(XI);if(aJ){var bJ=aJ;if($CLJS.vd(bJ)){var cJ=$CLJS.ic(bJ),Qia=$CLJS.jc(bJ),
Ria=cJ,Sia=$CLJS.D(cJ);XI=Qia;YI=Ria;ZI=Sia}else{var Tia=$CLJS.z(bJ);$CLJS.wE(Tia,$CLJS.H([$CLJS.Zr,$CLJS.mj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Kr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qq,new $CLJS.h(null,1,[$CLJS.si,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.ZD],null)],null)],null)],null)]));XI=$CLJS.B(bJ);YI=null;ZI=0}$I=0}else break}
for(var dJ=$CLJS.y(new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Dq,$CLJS.Fq,$CLJS.zq,$CLJS.Bq],null)),eJ=null,fJ=0,gJ=0;;)if(gJ<fJ){var Uia=eJ.X(null,gJ);$CLJS.sE.l(MI($CLJS.Tg([new $CLJS.P(null,2,5,$CLJS.Q,[0,1],null)])),Uia,$CLJS.H([$CLJS.Zr,$CLJS.mj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.aE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.aE],null)]));gJ+=1}else{var hJ=$CLJS.y(dJ);if(hJ){var iJ=hJ;if($CLJS.vd(iJ)){var jJ=$CLJS.ic(iJ),Via=$CLJS.jc(iJ),Wia=jJ,Xia=$CLJS.D(jJ);dJ=Via;eJ=Wia;fJ=Xia}else{var Yia=
$CLJS.z(iJ);$CLJS.sE.l(MI($CLJS.Tg([new $CLJS.P(null,2,5,$CLJS.Q,[0,1],null)])),Yia,$CLJS.H([$CLJS.Zr,$CLJS.mj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.aE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.aE],null)]));dJ=$CLJS.B(iJ);eJ=null;fJ=0}gJ=0}else break}
$CLJS.sE.l(MI($CLJS.Tg([new $CLJS.P(null,2,5,$CLJS.Q,[0,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[0,1],null)])),$CLJS.JE,$CLJS.H([$CLJS.Zr,$CLJS.mj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.aE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.aE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.aE],null)]));
$CLJS.sE.l(MI($CLJS.Tg([new $CLJS.P(null,2,5,$CLJS.Q,[1,3],null),new $CLJS.P(null,2,5,$CLJS.Q,[1,5],null),new $CLJS.P(null,2,5,$CLJS.Q,[0,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[0,4],null)])),$CLJS.PE,$CLJS.H([$CLJS.Zr,$CLJS.mj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.aE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.aE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.aE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.aE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.aE],null),new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.W,$CLJS.aE],null)]));
for(var kJ=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.IE,$CLJS.UE],null)),lJ=null,mJ=0,nJ=0;;)if(nJ<mJ){var Zia=lJ.X(null,nJ);$CLJS.uE(Zia,$CLJS.H([$CLJS.Zr,$CLJS.mj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.VD],null)]));nJ+=1}else{var oJ=$CLJS.y(kJ);if(oJ){var pJ=oJ;if($CLJS.vd(pJ)){var qJ=$CLJS.ic(pJ),$ia=$CLJS.jc(pJ),aja=qJ,bja=$CLJS.D(qJ);kJ=$ia;lJ=aja;mJ=bja}else{var cja=$CLJS.z(pJ);$CLJS.uE(cja,$CLJS.H([$CLJS.Zr,$CLJS.mj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.VD],null)]));kJ=$CLJS.B(pJ);
lJ=null;mJ=0}nJ=0}else break}
for(var rJ=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.KE,$CLJS.RE],null)),sJ=null,tJ=0,uJ=0;;)if(uJ<tJ){var dja=sJ.X(null,uJ);$CLJS.uE(dja,$CLJS.H([$CLJS.Zr,$CLJS.mj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.VD],null)]));uJ+=1}else{var vJ=$CLJS.y(rJ);if(vJ){var wJ=vJ;if($CLJS.vd(wJ)){var xJ=$CLJS.ic(wJ),eja=$CLJS.jc(wJ),fja=xJ,gja=$CLJS.D(xJ);rJ=eja;sJ=fja;tJ=gja}else{var hja=$CLJS.z(wJ);$CLJS.uE(hja,$CLJS.H([$CLJS.Zr,$CLJS.mj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.VD],null)]));rJ=$CLJS.B(wJ);
sJ=null;tJ=0}uJ=0}else break}
for(var yJ=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Aj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.pF,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),$CLJS.tr],null)],null),zJ=$CLJS.y(new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ZE,$CLJS.sF,$CLJS.gF,$CLJS.$E],null)),AJ=null,BJ=0,CJ=0;;)if(CJ<BJ){var DJ=AJ.X(null,CJ);$CLJS.GF.v(DJ,$CLJS.Zr,$CLJS.mj,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.gr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gq,DJ],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ss,$CLJS.tD,yJ],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,
$CLJS.dE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.dE],null)],null));CJ+=1}else{var EJ=$CLJS.y(zJ);if(EJ){var FJ=EJ;if($CLJS.vd(FJ)){var GJ=$CLJS.ic(FJ),ija=$CLJS.jc(FJ),jja=GJ,kja=$CLJS.D(GJ);zJ=ija;AJ=jja;BJ=kja}else{var HJ=$CLJS.z(FJ);$CLJS.GF.v(HJ,$CLJS.Zr,$CLJS.mj,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.gr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gq,HJ],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ss,$CLJS.tD,yJ],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.dE],null),new $CLJS.P(null,2,5,$CLJS.Q,
[$CLJS.W,$CLJS.dE],null)],null));zJ=$CLJS.B(FJ);AJ=null;BJ=0}CJ=0}else break}
$CLJS.GF.v($CLJS.cB,$CLJS.Zr,$CLJS.mj,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.gr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gq,$CLJS.cB],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ss,$CLJS.tD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Aj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ik,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),$CLJS.tr],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.SD],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dr,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ar,$CLJS.Qu,$CLJS.Vw,$CLJS.OE],null),new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.W,$CLJS.gE],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.YF],null)],null));$CLJS.GF.v($CLJS.FE,$CLJS.Zr,$CLJS.mj,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.gr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gq,$CLJS.FE],null),$CLJS.tD,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dr,$CLJS.fD,$CLJS.nD],null)],null));
$CLJS.Y($CLJS.NI,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Aj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gq,$CLJS.OI],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.aF,new $CLJS.P(null,17,5,$CLJS.Q,[$CLJS.ar,$CLJS.Gq,$CLJS.xF,$CLJS.PE,$CLJS.JE,$CLJS.Dq,$CLJS.zq,$CLJS.Fq,$CLJS.Bq,$CLJS.IE,$CLJS.UE,$CLJS.KE,$CLJS.RE,$CLJS.gF,$CLJS.$E,$CLJS.ZE,$CLJS.sF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.PI,$CLJS.Gi],null)],null));